
import { defineComponent, onMounted, ref } from 'vue';
import Slider from '../../../components/Slider.vue';
import SliderItem from '../../../components/SliderItem.vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
//import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';
import EntryIndex from '../../../components/Frontend/EntryIndex.vue';
export default defineComponent({
  meta: {
    de: {
      title: 'Marketplaces | Styria Media Group',
      keywords: ['marktplatz', 'kleinanzeigen', 'willhaben', 'njuskalo', 'bolha.com', 'bestby', 'styria'],
      description:
        'Zu den Styria Digital Marketplaces gehören Kleinanzeigenplattformen und Stellenbörsen wie willhaben, Njuškalo.hr, bolha.com und MojeDelo.com.',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'Marketplaces | Styria Media Group',
      keywords: ['marketplaces', 'kleinanzeigen', 'willhaben', 'njuskalo', 'bolha.com', 'bestby', 'styria'],
      description:
        'Styria Digital Marketplaces include classified ad platforms and job boards such as willhaben, Njuškalo.hr, bolha.com and MojeDelo.com.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Slider,
    SliderItem,
    EntryIndex,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    const slideShowImages = ref<Array<{ name: string; position: string }>>([
      { name: require('@/assets/img/marketplaces/header-img-v3.jpg'), position: 'center center' },
      { name: require('@/assets/img/marketplaces/header-img-v6.jpg'), position: 'top center' },
      { name: require('@/assets/img/marketplaces/header-img-v7.jpg'), position: 'center center' },
      { name: require('@/assets/img/marketplaces/header-img-v8.jpg'), position: 'top center' },
    ]);

    onMounted(() => {
      slideShowImages.value.sort((a, b) => 0.5 - Math.random());
    });

    return {
      slideShowImages,
      mdiChevronUp,
    };
  },
});
